import React from 'react'

import Block from '../waypoint-block'
import DetailsAccordion from '../details-accordion'

const QuestionSlider = ({
  title = 'Here are ways we can help',
  items = [],
}) => {
  return (
    <Block>
      <section className="s-container s-section s-readme__faq grid grid--2col-1-2">
        <h4>{title}</h4>
        <dl>
          {items.map((item, index) => (
            <DetailsAccordion
              key={index}
              heading={item.question}
              open={index === 0}
            >
              <div className="color-secondary">{item.answer}</div>
            </DetailsAccordion>
          ))}
        </dl>
      </section>
    </Block>
  )
}

export default QuestionSlider
