import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint'

export default class WaypointColorSwitch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      previousColor: this.props.previousColor
        ? this.props.previousColor
        : 'black',
      transitionColor: this.props.transitionColor
        ? this.props.transitionColor
        : 'white',
    }
  }
  handlePositionChange = ({ currentPosition }) => {
    // Protecting Gatsby from itself.
    if (typeof document !== 'undefined') {
      const wrapper = document.querySelector('#vrst-main')
      if (currentPosition === 'above') {
        wrapper.classList.add(`color-switch--${this.state.transitionColor}`)
        wrapper.classList.remove(`color-switch--${this.state.previousColor}`)
      } else {
        wrapper.classList.add(`color-switch--${this.state.previousColor}`)
        wrapper.classList.remove(`color-switch--${this.state.transitionColor}`)
      }
    }
  }

  render() {
    const topOffset = this.props.topOffset ? this.props.topOffset : '45%'
    const bottomOffset = this.props.bottomOffset
      ? this.props.bottomOffset
      : '45%'

    return (
      <React.Fragment>
        <Waypoint
          topOffset={topOffset}
          bottomOffset={bottomOffset}
          onPositionChange={this.handlePositionChange}
          scrollableAncestor={typeof window !== 'undefined' && window}
        />
        <span className="color-switch__siblings" />
      </React.Fragment>
    )
  }
}
