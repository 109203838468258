import React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 60 100"
      className="c-icon c-icon--product"
      preserveAspectRatio="xMinYMid meet"
    >
      <circle cx="30" cy="35" r="29" />
      <circle cx="30" cy="50" r="29" />
      <circle cx="30" cy="65" r="29" />
    </svg>
  )
}
