import React from 'react'

import Block from '../waypoint-block'

const PageIntro = props => {
  return (
    <Block>
      <section className="s-container s-section s-page__intro">
        <div className="fixed-width fixed-width--center">{props.children}</div>
      </section>
    </Block>
  )
}

export default PageIntro
