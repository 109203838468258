import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint'

export default class WaypointVideo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      position: 'below',
    }
  }
  handleChange = ({ currentPosition }) => {
    this.setState({
      position: currentPosition,
    })
  }
  render() {
    return (
      <Waypoint
        topOffset="37%"
        bottomOffset="10%"
        onPositionChange={this.handleChange}
      >
        <div className={`t-waypoint t-waypoint--${this.state.position}`}>
          {this.props.children}
        </div>
      </Waypoint>
    )
  }
}
