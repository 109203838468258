import React from 'react'

import Block from '../waypoint-block'
import VideoBlock from '../waypoint-video'

const VideoHeader = ({ videoUrl }) => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 400
  const videoParams = isMobile ? 'controls="true"' : 'autoPlay playsInLine'

  return (
    <VideoBlock>
      {/* Use `dangerouslySetInnerHTML` because React wants to strip `muted`. */}
      <div
        dangerouslySetInnerHTML={{
          __html: `
          <video ${videoParams} muted loop class="s-page__clients__main-video">
            <source src="${videoUrl}" type="video/mp4"/>
            <track src="/video/app-demo.vtt" kind="captions" srclang="en" label="captions">
            <track src="/video/app-demo.vtt" kind="descriptions" srclang="en" label="captions">
          </video>
        `,
        }}
      />
    </VideoBlock>
  )
}

const ImageHeader = ({ image }) => {
  return (
    <Block>
      <picture className="s-page__clients__main-image">
        <source
          sizes="100vw"
          srcSet={`${image.thumbnails.small_1x.url} 690w,
            ${image.thumbnails.medium_1x.url} 1260w,
            ${image.thumbnails.small_2x.url} 1380w,
            ${image.thumbnails.medium_2x.url} 2520w,
            ${image.thumbnails.large_1x.url} 2130w,
            ${image.thumbnails.large_2x.url} 4260w,`}
        />
        <img src={image.url} alt="" />
      </picture>
    </Block>
  )
}

const PageClient = ({ client, page }) => {
  const clientContent = JSON.parse(
    JSON.stringify(
      client.data.page_specific.find(item => item.page_type.includes(page))
        .content.document?.data ?? null
    )
  )

  if (clientContent === null) {
    return null
  }

  return (
    <Block>
      <div className="s-section">
        <h4 className="s-page__clients__heading">
          {client.data.name}
          {typeof clientContent.project_name !== 'undefined' && (
            <React.Fragment>
              <br />
              <span className="color-secondary">
                {clientContent.project_name}
              </span>
            </React.Fragment>
          )}
        </h4>
        {clientContent.video.url ? (
          <VideoHeader videoUrl={clientContent.video.url} />
        ) : (
          <ImageHeader image={clientContent.images.shift().image} />
        )}
        <p className="s-page__clients__body fixed-width fixed-width--center">
          {clientContent.description}
        </p>
        <div className="s-page__clients__slider">
          {clientContent.images.map(({ image }, index) => (
            <picture key={index}>
              <source
                sizes="100vw"
                srcSet={`${image.thumbnails.small_1x.url} 690w,
                  ${image.thumbnails.medium_1x.url} 1260w,
                  ${image.thumbnails.small_2x.url} 1380w,
                  ${image.thumbnails.medium_2x.url} 2520w,
                  ${image.thumbnails.large_1x.url} 2130w,
                  ${image.thumbnails.large_2x.url} 4260w,`}
              />
              <img src={image.url} alt="" />
            </picture>
          ))}
        </div>
      </div>
    </Block>
  )
}

export default PageClient
