import React from 'react'
import { Link } from "gatsby-plugin-react-i18next"

import Block from '../waypoint-block'

const PageSteps = ({
  heading = '',
  children,
  callToAction = 'Interested in working with us?',
}) => {
  return (
    <Block>
      <section className="s-container s-section grid grid--2col-1-1 s-page__steps">
        <div>
          <h4>{heading}</h4>
          {/* <button
            className="c-drift__button"
            onClick={() => {
              drift.api.sidebar.open('contact') // eslint-disable-line no-undef
            }}
          >
            {callToAction}
          </button> */}
          <Link to="/contact">{callToAction}</Link>
        </div>
        {children}
      </section>
    </Block>
  )
}

export default PageSteps
