import React, { useState, useEffect } from 'react'

const DetailsAccordion = ({ heading, subheading, children, open = false }) => {
  const [isOpen, setIsOpen] = useState(open)
  const [contentHeight, setContentHeight] = useState('0.01px')

  useEffect(() => {
    setContentHeight(isOpen ? 'max-content' : '0.01px')
  }, [isOpen])

  const toggleCollapsible = () => {
    setIsOpen(!isOpen)
    setContentHeight(!isOpen ? 'max-content' : '0.01px')
  }

  return (
    <>
      <dt data-open={isOpen}>
        <h4>
          <button
            type="button"
            aria-expanded={isOpen}
            onClick={toggleCollapsible}
          >
            {typeof subheading !== 'undefined' && (
              <React.Fragment>
                <span className="color-secondary">{subheading}</span>
                <br />
              </React.Fragment>
            )}
            {heading}
          </button>
        </h4>
      </dt>
      <dd
        style={{ maxHeight: contentHeight }}
        inert={!isOpen ? '' : undefined} // React doesn't support boolean `inert` yet, so we need strings.
      >
        <div>{children}</div>
      </dd>
    </>
  )
}

export default DetailsAccordion
